<script lang="ts">
  import { browser } from '$app/env';

  import { session } from '$app/stores';
  import { errorProvider } from '$lib/error-handling/errorProvider';
  import { invalidateUserStore } from '$lib/stores/getUserStore';
  import type { ClientUserStore } from 'shared/src';
  import { onDestroy } from 'svelte';
  import type { Unsubscriber } from 'svelte/store';
  export let clientUserStore: ClientUserStore;
  export let logoutText = 'Logout';
  let clazz = '';
  export { clazz as class };

  let destroyer: Unsubscriber | undefined;
  if (browser) {
    destroyer = session.subscribe(() => {});
  }

  onDestroy(() => {
    if (destroyer) {
      destroyer();
    }
  });
</script>

<button
  class={clazz}
  on:click={() => {
    clientUserStore.api
      .logout()
      .then(() => {
        invalidateUserStore(session);
        // It's important to use window.location instead of goto to break
        // sveltekit routing -- so that the back button doesn't have the
        // clientUserStore cached.
        window.location.href = '/';
      })
      .catch((err) => {
        console.error(err);
        errorProvider.error(err);
      });
  }}>{logoutText}</button
>
